.factory {
    padding: 60px 0;

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #2B2B2B;
        margin-bottom: 36px;
    }

    .factory_wrapper {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 20px;
        row-gap: 20px;
        padding: 10px 0;
    }
  
    .factory_wrap {
    transition: transform 0.3s ease-in-out;
    max-width: 100%;
    max-height: 300px;
    
    &:hover {
        cursor: pointer;
        border-radius: 14px;
        transform: scale(1.1);
      }
  
      .factory_img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        border-radius: 5px;

        &:hover {
            border-radius: 14px;
          }
      }
    }

    .factory_wrap1 {
        max-height: max-content;
    }

  }
  
  @media (max-width: 500px) {
    .factory {
    
        h2 {
            margin-bottom: 20px;
        }
        
        .factory_wrapper {
            grid-template-columns: auto;
            column-gap: 10px;
            row-gap: 10px;
        }

        .factory_wrap {
            &:hover {
                transform: scale(1);
              }
        }
      }
  }