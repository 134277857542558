.agro_product{
    margin-bottom: 120px;
    .agro_product_main{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        margin-bottom: 16px;
        img{
            display: block;
            width: 100%;
        }
    }
    .agro_product_main2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        img{
            display: block;
            width: 100%;
        }
    }
}

.classes_text {
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
    max-width: 1360px;
    margin: 0 auto;
    margin-bottom: 30px;
}

@media (max-width:500px) {
    .agro_product{
        margin-bottom: 32px;
        .agro_product_main{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin-bottom: 15px;
        }
        .agro_product_main2{
            display: block;
            img{
                display: block;
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }

    .classes_text {
        font-size: 14px;
        line-height: normal;
        text-align: justify;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 0 15px;
    }
}
