.home {




    .slider {
        overflow: hidden;
        margin-bottom: 120px;

        .swiper-pagination {
            max-width: 1270px;
            width: 100%;
            text-align: start;
            left: 0 !important;
            right: 0;
            margin: auto;
            bottom: 40px !important;

            .swiper-pagination-bullet {
                transition: all ease 0.5s;
                width: 20px;
                height: 4px;
                background: #8F8F8F;
                border-radius: 0;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                transition: all ease 0.5s;
                background: #FFFFFF;
            }
        }

        .swiper-slide {

            width: 100%;
            height: 70vh;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #202124 1.14%, rgba(32, 33, 36, 0.94) 21.93%, rgba(32, 33, 36, 0) 100%);
                background-position: center;
                z-index: -1;
            }

            .container {
                position: relative;
                height: 100%;

                .banner_info {
                    display: table;
                    position: absolute;
                    left: 0;
                    bottom: 0%;
                    top: 25%;
                    margin: auto;

                    h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 48px;
                        margin-bottom: 8px;


                        color: #FFFFFF;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 60%;


                        color: #CCCCCC;
                    }
                }
            }
        }
    }

    .home_info {
        font-size: 18px;
        color: #545454;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto;
    }
}

@media (max-width:500px) {
    .home {
        


        .slider {
            margin-bottom: 32px;

            .swiper-slide {
                height: 220px;

                .container {
                    .banner_info {
                        top: 32px;
                        margin: 0;
                        padding-left: 15px;
                        padding-right: 15px;

                        h1 {
                            font-size: 18px;
                            line-height: 24px;
                            text-align: center;
                        }

                        p {
                            font-size: 14px;
                            line-height: 16px;
                            text-align: center;
                            max-width: 100%;

                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }

            .swiper-pagination {
                padding-left: 15px;
                padding-right: 15px;
                max-width: 100%;
                bottom: 12px !important;

                .swiper-pagination-bullet {
                    width: 10px;
                    height: 1px;
                }
            }
        }

        .home_info {
            font-size: 14px;
            text-align:  justify;
            max-width: 1200px;
            padding: 0 15px;
        }

    }
}